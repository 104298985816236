import { createRouter, createWebHashHistory } from 'vue-router';
import Dashboard from './components/Dashboard.vue';

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                const date = new Date();
                const date2 = new Date(localStorage.time);
                if (date2.getTime() < date.getTime()) {
                    localStorage.removeItem("token");
                    return next({ name: 'login' });
                } else {
                    next();
                }
                // next()
            }
        }
    },
    {
        path: '/client',
        name: 'client',
        component: () => import('./pages/Client.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                const date = new Date();
                const date2 = new Date(localStorage.time);
                if (date2.getTime() < date.getTime()) {
                    localStorage.removeItem("token");
                    return next({ name: 'login' });
                } else {
                    next();
                }
                // next()
            }
        }
    },
    {
        path: '/fournisseur',
        name: 'fournisseur',
        component: () => import('./pages/Fournisseur.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                const date = new Date();
                const date2 = new Date(localStorage.time);
                if (date2.getTime() < date.getTime()) {
                    localStorage.removeItem("token");
                    return next({ name: 'login' });
                } else {
                    next();
                }
                // next()
            }
        }
    },

    {
        path: '/camion',
        name: 'camion',
        component: () => import('./pages/Camion.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                const date = new Date();
                const date2 = new Date(localStorage.time);
                if (date2.getTime() < date.getTime()) {
                    localStorage.removeItem("token");
                    return next({ name: 'login' });
                } else {
                    next();
                }
                // next()
            }
        }
    },
    {
        path: '/user',
        name: 'user',
        component: () => import('./pages/User.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                const date = new Date();
                const date2 = new Date(localStorage.time);
                if (date2.getTime() < date.getTime()) {
                    localStorage.removeItem("token");
                    return next({ name: 'login' });
                } else {
                    next();
                }
                // next()
            }
        }
    },
    {
        path: '/objectif',
        name: 'objectif',
        component: () => import('./pages/objectif/Objectif.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                const date = new Date();
                const date2 = new Date(localStorage.time);
                if (date2.getTime() < date.getTime()) {
                    localStorage.removeItem("token");
                    return next({ name: 'login' });
                } else {
                    next();
                }
                // next()
            }
        }
    },
    {
        path: '/userobjectifs/:id?',
        name: 'userobjectifs',
        component: () => import('./pages/objectif/UserObjectifs.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                const date = new Date();
                const date2 = new Date(localStorage.time);
                if (date2.getTime() < date.getTime()) {
                    localStorage.removeItem("token");
                    return next({ name: 'login' });
                } else {
                    next();
                }
                // next()
            }
        }
    },

    {
        path: '/roles',
        name: 'role',
        component: () => import('./pages/Role.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                const date = new Date();
                const date2 = new Date(localStorage.time);
                if (date2.getTime() < date.getTime()) {
                    localStorage.removeItem("token");
                    return next({ name: 'login' });
                } else {
                    next();
                }
                // next()
            }
        }
    },
    {
        path: '/order',
        name: 'order',
        component: () => import('./pages/order/Order.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                const date = new Date();
                const date2 = new Date(localStorage.time);
                if (date2.getTime() < date.getTime()) {
                    localStorage.removeItem("token");
                    return next({ name: 'login' });
                } else {
                    next();
                }
                // next()
            }
        }
    },
    {
        path: '/supplierorder',
        name: 'supplierorder',
        component: () => import('./pages/order/SupplierOrder.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                const date = new Date();
                const date2 = new Date(localStorage.time);
                if (date2.getTime() < date.getTime()) {
                    localStorage.removeItem("token");
                    return next({ name: 'login' });
                } else {
                    next();
                }
                // next()
            }
        }
    },
    {
        path: '/fournisseurorder',
        name: 'fournisseurorder',
        component: () => import('./pages/order/FournisseurOrder.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                const date = new Date();
                const date2 = new Date(localStorage.time);
                if (date2.getTime() < date.getTime()) {
                    localStorage.removeItem("token");
                    return next({ name: 'login' });
                } else {
                    next();
                }
                // next()
            }
        }
    },

    {
        path: '/orderlist',
        name: 'orderlist',
        component: () => import('./pages/order/Orderlist.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                const date = new Date();
                const date2 = new Date(localStorage.time);
                if (date2.getTime() < date.getTime()) {
                    localStorage.removeItem("token");
                    return next({ name: 'login' });
                } else {
                    next();
                }
                // next()
            }
        }
    },
    {
        path: '/retour',
        name: 'retour',
        component: () => import('./pages/Retour.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                const date = new Date();
                const date2 = new Date(localStorage.time);
                if (date2.getTime() < date.getTime()) {
                    localStorage.removeItem("token");
                    return next({ name: 'login' });
                } else {
                    next();
                }
                // next()
            }
        }
    },
    {
        path: '/order/update/:id?',
        name: 'updateorder',
        component: () => import('./pages/order/Update.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                const date = new Date();
                const date2 = new Date(localStorage.time);
                if (date2.getTime() < date.getTime()) {
                    localStorage.removeItem("token");
                    return next({ name: 'login' });
                } else {
                    next();
                }
                // next()
            }
        }
    },
    {
        path: '/order/updateorderfour/:id?',
        name: 'updateorderfour',
        component: () => import('./pages/order/Updateorderfour.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                const date = new Date();
                const date2 = new Date(localStorage.time);
                if (date2.getTime() < date.getTime()) {
                    localStorage.removeItem("token");
                    return next({ name: 'login' });
                } else {
                    next();
                }
                // next()
            }
        }
    },
    {
        path: '/produit',
        name: 'produit',
        component: () => import('./pages/Produit.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                const date = new Date();
                const date2 = new Date(localStorage.time);
                if (date2.getTime() < date.getTime()) {
                    localStorage.removeItem("token");
                    return next({ name: 'login' });
                } else {
                    next();
                }
                // next()
            }
        }
    },
    {
        path: '/categorie',
        name: 'categorie',
        component: () => import('./pages/Categorie.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                const date = new Date();
                const date2 = new Date(localStorage.time);
                if (date2.getTime() < date.getTime()) {
                    localStorage.removeItem("token");
                    return next({ name: 'login' });
                } else {
                    next();
                }
                // next()
            }
        }
    },

    {
        path: '/configuration',
        name: 'configuration',
        component: () => import('./pages/Configuration.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                const date = new Date();
                const date2 = new Date(localStorage.time);
                if (date2.getTime() < date.getTime()) {
                    localStorage.removeItem("token");
                    return next({ name: 'login' });
                } else {
                    next();
                }
                // next()
            }
        }
    },
    {
        path: '/editprofile',
        name: 'editprofile',
        component: () => import('./pages/EditProfile.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                const date = new Date();
                const date2 = new Date(localStorage.time);
                if (date2.getTime() < date.getTime()) {
                    localStorage.removeItem("token");
                    return next({ name: 'login' });
                } else {
                    next();
                }
                // next()
            }
        }
    },


    // {
    //     path: '/formlayout',
    //     name: 'formlayout',
    //     component: () => import('./components/FormLayoutDemo.vue'),
    //     beforeEnter: (to, form, next) => {

    //         if (localStorage.token == '' || localStorage.token == null) {
    //             return next({ name: 'login' })
    //         } else {
    //             next()
    //         }
    //     }
    // },
    // {
    //     path: '/input',
    //     name: 'input',
    //     component: () => import('./components/InputDemo.vue'),

    // },
    // {
    //     path: '/floatlabel',
    //     name: 'floatlabel',
    //     component: () => import('./components/FloatLabelDemo.vue')
    // },
    // {
    //     path: '/invalidstate',
    //     name: 'invalidstate',
    //     component: () => import('./components/InvalidStateDemo.vue')
    // },
    // {
    //     path: '/button',
    //     name: 'button',
    //     component: () => import('./components/ButtonDemo.vue')
    // },
    // {
    //     path: '/table',
    //     name: 'table',
    //     component: () => import('./components/TableDemo.vue')
    // },
    // {
    //     path: '/list',
    //     name: 'list',
    //     component: () => import('./components/ListDemo.vue')
    // },
    // {
    //     path: '/tree',
    //     name: 'tree',
    //     component: () => import('./components/TreeDemo.vue')
    // },
    // {
    //     path: '/panel',
    //     name: 'panel',
    //     component: () => import('./components/PanelsDemo.vue')
    // },
    // {
    //     path: '/overlay',
    //     name: 'overlay',
    //     component: () => import('./components/OverlayDemo.vue')
    // },
    // {
    //     path: '/media',
    //     name: 'media',
    //     component: () => import('./components/MediaDemo.vue')
    // },
    // {
    //     path: '/menu',
    //     component: () => import('./components/MenuDemo.vue'),
    //     children: [
    //         {
    //             path: '',
    //             component: () => import('./components/menu/PersonalDemo.vue')
    //         },
    //         {
    //             path: '/menu/seat',
    //             component: () => import('./components/menu/SeatDemo.vue')
    //         },
    //         {
    //             path: '/menu/payment',
    //             component: () => import('./components/menu/PaymentDemo.vue')
    //         },
    //         {
    //             path: '/menu/confirmation',
    //             component: () => import('./components/menu/ConfirmationDemo.vue')
    //         },
    //     ],
    // },
    // {
    //     path: '/messages',
    //     name: 'messages',
    //     component: () => import('./components/MessagesDemo.vue')
    // },
    // {
    //     path: '/file',
    //     name: 'file',
    //     component: () => import('./components/FileDemo.vue')
    // },
    // {
    //     path: '/chart',
    //     name: 'chart',
    //     component: () => import('./components/ChartDemo.vue')
    // },
    // {
    //     path: '/misc',
    //     name: 'misc',
    //     component: () => import('./components/MiscDemo.vue')
    // },
    // {
    //     path: '/crud',
    //     name: 'crud',
    //     component: () => import('./pages/CrudDemo.vue')
    // },
    // {
    //     path: '/timeline',
    //     name: 'timeline',
    //     component: () => import('./pages/TimelineDemo.vue')
    // },
    // {
    //     path: '/empty',
    //     name: 'empty',
    //     component: () => import('./components/EmptyPage.vue')
    // },
    // {
    //     path: '/documentation',
    //     name: 'documentation',
    //     component: () => import('./components/Documentation.vue')
    // },
    // {
    //     path: '/blocks',
    //     name: 'blocks',
    //     component: () => import('./components/BlocksDemo.vue')
    // },
    // {
    //     path: '/icons',
    //     name: 'icons',
    //     component: () => import('./components/IconsDemo.vue')
    // },
    // // {
    // //     path: '/landing',
    // //     name: 'landing',
    // //     component: () => import('./pages/LandingDemo.vue')
    // // },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    // {
    //     path: '/error',
    //     name: 'error',
    //     component: () => import('./pages/Error.vue')
    // },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    }

];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});
router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (localStorage.user) {
            next();
            return;
        }
        next({ name: 'login' });
    } else {
        next();
    }
});

export default router;
