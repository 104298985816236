<template>
  <div class="layout-footer">
    <img alt="Logo" :src="footerImage()" height="20" class="mr-2" />
    by
    <span class="font-medium ml-2"> <a href="https://www.linkedin.com/in/saad-developer-freelance-laravel-wordpress-vuejs-enseignant-ecommerce/" target="_blank" rel="noopener noreferrer">Saad Tejda</a> </span>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
  methods: {
    footerImage() {
      return this.$appState.darkTheme ? "images/logo.png" : "images/logo.png";
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
};
</script>